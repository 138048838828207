import * as React from 'react';
import {Link} from 'gatsby';
import Layout from '../components/Layout';
import Module from '../components/Module';

const NotFoundPage = () => (
  <Layout pageTitle="404 Not Found" description="Miter's 404 page — not found">
    <Module>
      <h1>Page Not Found</h1>
      <div className="BodyHuge">
        <p>
          Miter can't locate a page at this address. Any chance there's a typo in your URL? Or, you can go directly to{' '}
          <Link to="/">Miter's home page</Link>.
        </p>
        <p>Thanks and sorry for the inconvenience.</p>
      </div>
    </Module>
  </Layout>
);

export default NotFoundPage;
